import * as Logger from '@common/Logger';
import { injectScript } from '@tsUtils';
import postal from 'postal';
import { useEffect, useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { useDebounceValue, useWindowSize } from 'usehooks-ts';
import { SCRIPT_STATE } from '../constants';
import { useAppSelector } from '../redux';
import { setAaltraScriptState } from '../redux/actions';
import {
    isAirToAirCoolingLead,
    isAirToAirHeatingLead,
    isAirToAirMultiRoomLead,
    isAirToWaterProject,
    shouldInjectAaltraScript,
} from '../redux/selectors';
import { useQuotationAtaTool, useQuotationAtwTool } from './quotationHooks';
import {
    applySizingAtaCoolingTool,
    applySizingAtaHeatingTool,
    applySizingAtaMultiRoomTool,
    useSizingAtwTool,
} from './sizingHooks';

export * from './evsHooks';
export * from './gigyaHooks';
export * from './incentiveHooks';

const CHANNEL_NAME = 'rsn';

let rsnChannel: IChannelDefinition<unknown>;

export const usePostal = (): void => {
    useEffect(() => {
        // Create channel or fetch from window
        rsnChannel = window._rsnChannel || postal.channel(CHANNEL_NAME);
        // Expose rsnChannel on window object after creation
        window._rsnChannel = rsnChannel;
        // Log all postal events
        const logPostal = (data: unknown, env: IEnvelope<unknown>): void => {
            if (data) Logger.log(`[📯] ${env.topic}`, Logger.STYLE.DEFAULT, data);
            else Logger.log(`[📯] ${env.topic}`, Logger.STYLE.DEFAULT);
        };
        window._rsnChannel.subscribe('*.*.*', logPostal);
        window._rsnChannel.subscribe('*.*', logPostal);
    }, []);
};

export const useAaltraScriptInjection = (dispatch: Dispatch): void => {
    // Fetch store data
    const shouldInjectScript = useAppSelector(shouldInjectAaltraScript);
    const scriptUrl = useAppSelector((store) => store.settings.urls?.aaltraBundle);
    // Script injection
    useEffect(() => {
        if (shouldInjectScript && scriptUrl) {
            // Inject Aaltra sizing-bundle
            const scriptId = '__aaltra-bundle';
            injectScript(
                scriptId,
                scriptUrl,
                () => dispatch(setAaltraScriptState(SCRIPT_STATE.injected)),
                () => dispatch(setAaltraScriptState(SCRIPT_STATE.error)),
            );
        }
    }, [shouldInjectScript, scriptUrl]);
};

export const useSizingTool = (dispatch: Dispatch): void => {
    const isAirToWater = useAppSelector(isAirToWaterProject);
    const isAtaMultiRoomLead = useAppSelector(isAirToAirMultiRoomLead);
    const isAtaHeatingLead = useAppSelector(isAirToAirHeatingLead);
    const isAtaCoolingLead = useAppSelector(isAirToAirCoolingLead);

    if (isAirToWater) {
        // Disabled eslint react-hooks rule for this line because the user first needed to select a room type (ATW or ATA)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSizingAtwTool(dispatch);
    } else {
        // Disabled eslint react-hooks rule for these lines because the correct sizing tool for ATA can only be rendered after the user selected an airToAirType
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            postal.unsubscribeFor({ channel: CHANNEL_NAME, topic: 'sizing.cooling.*' });
            postal.unsubscribeFor({ channel: CHANNEL_NAME, topic: 'sizing.winterheating.*' });
            postal.unsubscribeFor({ channel: CHANNEL_NAME, topic: 'sizing.airtoair.*' });
            if (isAtaMultiRoomLead) applySizingAtaMultiRoomTool(dispatch);
            if (isAtaHeatingLead) applySizingAtaHeatingTool(dispatch);
            if (isAtaCoolingLead) applySizingAtaCoolingTool(dispatch);
        }, [dispatch, isAtaHeatingLead, isAtaCoolingLead, isAtaMultiRoomLead]);
    }
};

export const useQuotationTool = (dispatch: Dispatch): void => {
    const isAirToWater = useAppSelector(isAirToWaterProject);
    if (isAirToWater) {
        // Disabled eslint react-hooks rule for this line because the user first needed to select a project type (ATW or ATA)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuotationAtwTool(dispatch);
    } else {
        // Disabled eslint react-hooks rule for this line because the user first needed to select a project type (ATW or ATA)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuotationAtaTool(dispatch);
    }
};
