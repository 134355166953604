/* eslint-disable indent */
import { AirToAirLead, Lead } from '../../types';
import {
    ActionsType,
    APP_STATE_ACTIONS,
    INCENTIVES_DE,
    INCENTIVES_DRAPO,
    LEAD_ACTIONS,
    SelectAirToAirSolutionAction,
    TOOL_QUOTATION_ATA,
    TOOL_QUOTATION_ATW,
    TOOL_SIZING_ATA_COOLING,
    TOOL_SIZING_ATA_HEATING,
    TOOL_SIZING_ATA_MULTI_ROOM,
    TOOL_SIZING_ATW,
} from '../actions';
import { getPurgedLead } from '../selectors/leadSelectors';
import incentives from './incentives';
import toolData from './toolData';

const initialState: Partial<Lead> = {};

const lead = (state: Lead, action: ActionsType): Lead => {
    switch (action.type) {
        case APP_STATE_ACTIONS.navigationRequest:
            // Reset certain lead data when going backwards
            // .split('.')[0] to strip off any tool subpages
            return {
                ...state,
                ...getPurgedLead(state, action.page),
            } as Lead;
        case LEAD_ACTIONS.clearLead:
            return {} as Lead;
        case LEAD_ACTIONS.setLeadLocal:
            // setLeadLocal was dispatched to trigger logic & set isSaving to false
            if (!action.lead) return state;
            // initialLead should not be selective, set all keys
            if (action.setAllValues) return { ...state, ...action.lead } as Lead;
            // Only save values that could have been updated by backend
            return {
                ...state,
                // App state
                status: action.lead.status!,
                version: action.lead.version!,
                country: action.lead.country!,
                language: action.lead.language!,
                incentives: action.lead.incentives!,
                phasing: action.lead.phasing || null,
                // Data configured by backend
                location: action.lead.location || null,
                solution: action.lead.solution || null,
                solutionType: action.lead.solutionType!,
                solutionVersion: action.lead.solutionVersion || null,
                allowsSolutionVersions: action.lead.allowsSolutionVersions!,
                options: action.lead.options || [],
                prices: action.lead.prices || null,
                units: action.lead.units || null,
                skipToContactDealerFrom: action.lead.skipToContactDealerFrom || null,
                swapCooling: action.lead.swapCooling || false,
                energyPrices: action.lead.energyPrices || null,
                multiPlusType: (action.lead as AirToAirLead).multiPlusType || null,
            } as Lead;
        case LEAD_ACTIONS.updateLocation:
            return { ...state, ...action.lead } as Lead;
        case LEAD_ACTIONS.setSelSoftData:
            return {
                ...state,
                // Note the difference in casing: selSoftData (remote) <-> selsoftData (local)
                selSoftData: action.selsoftData,
                // New selsoft-solution means new prices, reset them and let commerceLogic do its thing
                prices: null,
            };
        case LEAD_ACTIONS.selectSolution: {
            const { colorVariation } = action as SelectAirToAirSolutionAction;
            return {
                ...state,
                solutionId: action.solutionId,
                colorVariation: colorVariation || state.colorVariation,
            };
        }
        case LEAD_ACTIONS.setSftAirToWater:
        case LEAD_ACTIONS.setSftAirToAir:
            return { ...state, ...action.choices };
        case LEAD_ACTIONS.setSchedulingFactor:
            return {
                ...state,
                schedulingFactor: action.schedulingFactor,
            };
        case LEAD_ACTIONS.setDealerId:
            return {
                ...state,
                dealerId: action.dealerId,
            };
        case LEAD_ACTIONS.setCustomerDetails:
            return {
                ...state,
                customerDetails: action.customerDetails,
            };
        case LEAD_ACTIONS.updateLiterature:
            return { ...state, literatureData: action.data };
        case LEAD_ACTIONS.setEnergyLabels:
            return { ...state, energyLabelsData: action.energyLabelsData } as Lead;
        case LEAD_ACTIONS.setMultiPlusType: {
            return { ...state, multiPlusType: action.multiPlusType };
        }
        case LEAD_ACTIONS.setMultiPlusEnergyLabels:
            return {
                ...state,
                multiPlusEnergyLabelsData: action.multiPlusEnergyLabelsData,
            } as Lead;
        // Pass actions to sub-reducer
        case TOOL_SIZING_ATW.update: {
            const postal = action.data.tools['heating-sizing']?.postal;
            const isNewLocation = postal !== state.location?.postalCode;
            return {
                ...state,
                // Reset location if postal has changed
                location: isNewLocation ? null : state.location,
                toolData: toolData(state.toolData, action),
            };
        }
        // Pass actions to sub-reducer
        case TOOL_SIZING_ATA_COOLING.update: {
            const postal = action.data.tools['cooling-sizing']?.postal;
            const isNewLocation = postal !== state.location?.postalCode;
            return {
                ...state,
                // Reset location if postal has changed
                location: isNewLocation ? null : state.location,
                toolData: toolData(state.toolData, action),
            };
        }
        case TOOL_SIZING_ATA_HEATING.update: {
            const postal = action.data.tools['winter-heating-sizing']?.postal;
            const isNewLocation = postal !== state.location?.postalCode;
            return {
                ...state,
                // Reset location if postal has changed
                location: isNewLocation ? null : state.location,
                toolData: toolData(state.toolData, action),
            };
        }
        case TOOL_SIZING_ATA_MULTI_ROOM.update: {
            const postal = action.data.tools['air-to-air']?.postal;
            const isNewLocation = postal !== state.location?.postalCode;
            return {
                ...state,
                // Reset location if postal has changed
                location: isNewLocation ? null : state.location,
                toolData: toolData(state.toolData, action),
            };
        }
        case TOOL_SIZING_ATW.init:
        case TOOL_SIZING_ATA_COOLING.init:
        case TOOL_SIZING_ATA_HEATING.init:
        case TOOL_SIZING_ATA_MULTI_ROOM.init:
        case TOOL_SIZING_ATW.done:
        case TOOL_SIZING_ATA_COOLING.done:
        case TOOL_SIZING_ATA_HEATING.done:
        case TOOL_SIZING_ATA_MULTI_ROOM.done:
        case TOOL_QUOTATION_ATW.init:
        case TOOL_QUOTATION_ATW.update:
        case TOOL_QUOTATION_ATW.done:
        case TOOL_QUOTATION_ATA.init:
        case TOOL_QUOTATION_ATA.update:
        case TOOL_QUOTATION_ATA.done:
            return {
                ...state,
                toolData: toolData(state.toolData, action),
            };
        case INCENTIVES_DRAPO.setAmountOfPeople:
        case INCENTIVES_DRAPO.setIncomeRanges:
        case INCENTIVES_DRAPO.setDrapoData:
        case INCENTIVES_DRAPO.setDrapoToolState:
        case INCENTIVES_DE.update:
        case INCENTIVES_DE.done:
            return {
                ...state,
                incentives: incentives(state.incentives, action),
            };
        default:
            return state || initialState;
    }
};

export default lead;
