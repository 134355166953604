import {
    Store,
    AirToWaterLead,
    PlatformDataQuotationAtw,
    PlatformDataQuotationAta,
    ToolsDataQuotationAtw,
    ToolsDataQuotationAta,
    ToolData,
} from '../../../types';
import {
    isAirToWaterProject,
    isAirToAirHeatingLead,
    isAirToAirMultiRoomLead,
} from '../genericSelectors';

// --- Platform JSON -----------------------------------------------------------

const getQuotationAtwPlatformJson = (store: Store): PlatformDataQuotationAtw => {
    const lead = store.lead as AirToWaterLead;
    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        zoning: lead.emitterType!.includes('_') ? 'multi' : 'single',
        family: { sfh: 'single', mfh: 'multi' }[lead.buildingType!],
        leadId: store.lead.id,
    };
};

const getQuotationAtaPlatformJson = (store: Store): PlatformDataQuotationAta => {
    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        leadId: store.lead.id,
    };
};

export const getQuotationPlatformJson = (
    store: Store,
): PlatformDataQuotationAtw | PlatformDataQuotationAta | null => {
    return isAirToWaterProject(store)
        ? getQuotationAtwPlatformJson(store)
        : getQuotationAtaPlatformJson(store);
};

// --- Tools JSON --------------------------------------------------------------

const getQuotationAtwToolsJson = (store: Store, isInit?: boolean): ToolsDataQuotationAtw | null => {
    if (!store.lead.toolData) return null;
    const { sizingHeating, quotationHeating } = store.lead.toolData as ToolData;
    return {
        'heating-sizing': sizingHeating,
        'heating-quotation': isInit ? {} : quotationHeating || {},
    };
};

const getQuotationAtaToolsJson = (store: Store, isInit?: boolean): ToolsDataQuotationAta | null => {
    if (!store.lead.toolData) return null;
    const { sizingCooling, sizingWinterHeating, quotationCooling, sizingMultiRoom } = store.lead
        .toolData as ToolData;
    if (isAirToAirMultiRoomLead(store)) {
        return {
            'air-to-air': sizingMultiRoom,
            'cooling-quotation': isInit ? {} : quotationCooling || {},
        };
    } else if (isAirToAirHeatingLead(store)) {
        return {
            'winter-heating-sizing': sizingWinterHeating,
            'cooling-quotation': isInit ? {} : quotationCooling || {},
        };
    } else {
        return {
            'cooling-sizing': sizingCooling,
            'cooling-quotation': isInit ? {} : quotationCooling || {},
        };
    }
};

export const getQuotationToolsJson = (
    store: Store,
    isInit?: boolean,
): ToolsDataQuotationAtw | ToolsDataQuotationAta | null => {
    return isAirToWaterProject(store)
        ? getQuotationAtwToolsJson(store, isInit)
        : getQuotationAtaToolsJson(store, isInit);
};

// -- Solution JSON ------------------------------------------------------------

export const getQuotationUnitsJson = (store: Store): Record<string, string | number | null> => {
    if (isAirToWaterProject(store)) {
        const { units } = store.lead;
        return {
            indoor: units?.indoor?.join(' + ') || null,
            outdoor: units?.outdoor?.[0] || null,
            tank: units?.tank?.[0] || null,
            boiler: units?.boiler?.[0] || null,
        };
    } else if (isAirToAirHeatingLead(store)) {
        return store.lead.toolData?.sizingWinterHeating?.solution || {};
    } else {
        // is AirToAirCooling
        return store.lead.toolData?.sizingCooling?.solution || {};
    }
};
