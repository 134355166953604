/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { APP_STATUS, SCRIPT_STATE } from '../../../constants';
import { Store } from '../../../types';
import {
    InitSizingToolAtaMultiRoomAction,
    TOOL_SIZING_ATA_MULTI_ROOM,
    UpdateSizingToolAtaMultiRoomAction,
    setLeadRemote,
} from '../../actions';
import { getSizingPlatformJson, getSizingToolsJson } from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitSizingToolAtaMultiRoomAction };
const initSizingAtaMultiRoomLogic = createLogic({
    type: TOOL_SIZING_ATA_MULTI_ROOM.init,
    name: 'sizing.ataMultiRoom.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (
            state.appState.aaltraScriptState === SCRIPT_STATE.injected &&
            state.lead.status === APP_STATUS.sp_sizing_tool
        ) {
            window._rsnChannel.publish('sizing.airtoair.init', {
                target: 'div#external-tool-sizing-ata-multi-room',
                ctaTarget: 'div#cta-teleport-target',
                platform: getSizingPlatformJson(state),
                tools: null,
                step: null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = { action: UpdateSizingToolAtaMultiRoomAction };
const updateSizingAtaMultiRoomApiLogic = createLogic({
    type: TOOL_SIZING_ATA_MULTI_ROOM.update,
    name: 'sizing.ataMultiRoom.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_sizing_tool,
                `${APP_STATUS.sp_sizing_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const doneSizingAtaMultiRoomApiLogic = createLogic({
    type: TOOL_SIZING_ATA_MULTI_ROOM.done,
    name: 'sizing.ataMultiRoom.done',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        if (state.lead.toolData!.sizingMultiRoom!['no-solution']) {
            dispatch(setLeadRemote(APP_STATUS.dealer_selection));
        } else {
            dispatch(setLeadRemote(APP_STATUS.sp_quotation_tool));
        }
        done();
    },
});

export default [
    initSizingAtaMultiRoomLogic,
    updateSizingAtaMultiRoomApiLogic,
    doneSizingAtaMultiRoomApiLogic,
];
